import { fork } from "redux-saga/effects";
import general from "./general";
import project from "./project";
import chats from "./chats";

export default function* root() {
  yield fork(general);
  yield fork(project);
  yield fork(chats);
}
