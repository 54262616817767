const ENV_CONFIG = {
  asugpt: {
    prod: {
      service: "https://auth-main.aiml.asu.edu",
      appName: "asugpt",
      appId: "sieqiG7TpyD5i93ChCBcmP",
      appUrl: "https://api-main.aiml.asu.edu/",
      socketUrl: "wss://apiws-main.aiml.asu.edu/",
    },
    beta: {
      service: "https://auth-main-beta.aiml.asu.edu",
      appName: "asugpt",
      appId: "sieqiG7TpyD5i93ChCBcmP",
      appUrl: "https://api-main-beta.aiml.asu.edu/",
      socketUrl: "wss://apiws-main-beta.aiml.asu.edu/",
    },
    poc: {
      service: "https://auth-main-poc.aiml.asu.edu",
      appName: "asugpt",
      appId: "sieqiG7TpyD5i93ChCBcmP",
      appUrl: "https://api-main-poc.aiml.asu.edu/",
      socketUrl: "wss://apiws-main-poc.aiml.asu.edu/",
    },
  },
  projectapp: {
    prod: {
      service: "https://auth-main.aiml.asu.edu",
      appName: "app",
      appId: "dzDaH9TWtRtBSq8oPhuj3P",
      appUrl: "https://api-main.aiml.asu.edu/",
      socketUrl: "wss://apiws-main.aiml.asu.edu/",
    },
    beta: {
      service: "https://auth-main-beta.aiml.asu.edu",
      appName: "app",
      appId: "dzDaH9TWtRtBSq8oPhuj3P",
      appUrl: "https://api-main-beta.aiml.asu.edu/",
      socketUrl: "wss://apiws-main-beta.aiml.asu.edu/",
    },
    poc: {
      service: "https://auth-main-poc.aiml.asu.edu",
      appName: "app",
      appId: "dzDaH9TWtRtBSq8oPhuj3P",
      appUrl: "https://api-main-poc.aiml.asu.edu/",
      socketUrl: "wss://apiws-main-poc.aiml.asu.edu/",
    },
  },
};

export default ENV_CONFIG;
