import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, Tooltip } from "antd";
import { Button } from "../../../../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUp,
  faMicrophone,
  faPaperclip,
  faSquare,
  faStop,
  faHeadphones,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { promptFilesValidation, toastAlert } from "../../../../../utils";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import clsx from "clsx";
import {
  ALERT_TYPES,
  AUDIO_ACCESS_PROJECTS,
  SPEECH_ACCESS_PROJECTS,
} from "../../../../../constants";
import { BounceLoader } from "react-spinners";
import "./styles.scss";

const InputBox = React.forwardRef(
  (
    {
      projectId,
      sendMessage,
      isLoading,
      isProjectApp,
      projectData,
      appConfig,
      stopResponseGenerating,
      removeSelectedFile,
      selectFile,
      fileLoading,
      selectedModel,
      selectedFile,
      audioBoxPreviewHandler,
    },
    ref
  ) => {
    // STATES
    const [message, setMessage] = useState("");
    const [fileLoadingState, setFileLoadingState] = useState({});

    // CONST VALS
    const [form] = Form.useForm();
    const uploadFileInput = useRef(null);
    const isFilesLoading = Object.entries(fileLoadingState).length !== 0;
    const audioBoxPreview =
      isProjectApp && AUDIO_ACCESS_PROJECTS.includes(projectId);
    const speechPreview =
      SPEECH_ACCESS_PROJECTS.includes(projectId) || !isProjectApp;
    const inputPlaceHolder = appConfig?.inputBoxPlaceholder;
    const discalimerMessage = appConfig?.desclaimerMessage;
    const selectedImages = selectedFile.filter((file) => file.type === "image");
    const fileSupportWarning =
    selectedImages.length > 0 && !selectedModel.imageSupport;
    const {
      transcript,
      listening,
      resetTranscript,
      browserSupportsSpeechRecognition,
      browserSupportsContinuousListening,
    } = useSpeechRecognition();

    // HANDLERS
    // const handleUpload = (file, index) => {
    //   const totalSize = file.size;
    //   let uploadedSize = 0;

    //   const interval = setInterval(() => {
    //     uploadedSize += totalSize / 10;
    //     const progress = Math.min((uploadedSize / totalSize) * 100, 100);
    //     setUploadProgress((prev) => ({ ...prev, [index]: progress }));

    //     if (progress === 100) {
    //       clearInterval(interval);
    //     }
    //   }, 1000);
    // };
    const uploadFileHandler = (e) => {
      const files = e.target.files;
      let uploadedFiles = [];
      if (!files) return;

      const filesArray = Array.from(files);
      const totalfiles = selectedFile.length + filesArray.length;

      if (totalfiles > 5)
        toastAlert("You can only upload 5 files at a time.", ALERT_TYPES.ERROR);

      for (const file of filesArray) {
        const isExist = [...selectedFile, ...uploadedFiles].find(
          (item) => item.name === file.name
        );
        if (isExist) {
          toastAlert(
            `${file.name} is already uploaded. Please upload a different file.`,
            ALERT_TYPES.ERROR
          );
        } else {
          if (promptFilesValidation(file)) {
            uploadedFiles.push(file);
            if (uploadedFiles.length >= 5) break;
          }
        }
      }

      selectFile(uploadedFiles);
      if (uploadFileInput.current) uploadFileInput.current.value = "";
    };

    const recordingHandler = () => {
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
        return;
      }
      setMessage("");
      SpeechRecognition.startListening({
        continuous: browserSupportsContinuousListening,
      });
    };

    const setMessageHandler = (e) => {
      setMessage(e.target.value);
    };

    const generateMessageHandler = () => {
      if (isLoading) return stopResponseGenerating();
      if (message.trim() === "") return;
      if (isFilesLoading) return;

      setMessage("");
      sendMessage(message, selectedFile);
      if (listening) {
        SpeechRecognition.stopListening();
        resetTranscript();
      }
    };

    const handleKeyPress = (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        generateMessageHandler();
      }
    };

    // HOOKS
    const inputRef = useCallback(
      (node) => {
        if (!node) return;
        // set textarea height
        node.style.height = "31px";
        const nodeHeight = node.scrollHeight;
        node.style.height = nodeHeight + "px";

        // set chat wrapper height
        if (!ref || !ref.current) return;
        const contentSpacing = window.innerWidth > 768 ? 95 : 65;
        const screenHeight = window.innerWidth > 768 ? 130 : 115;
        const defaultHeight =
          selectedFile.length > 0
            ? screenHeight + contentSpacing
            : screenHeight;

        const textareaHeight =
          nodeHeight > 20 ? nodeHeight - 40 : nodeHeight - 20;
        const calcheight = textareaHeight + defaultHeight;
        ref.current.style.height = `calc(100% - ${calcheight}px)`;
      },
      [message, selectedFile]
    );

    useEffect(() => {
      if (transcript !== "") {
        setMessage(transcript);
      }
    }, [transcript]);

    useEffect(() => {
      const currentlySelectedFiles = new Set(
        selectedFile.map((file) => file.name)
      );

      setFileLoadingState((prevLoading) => {
        const newLoadingState = { ...prevLoading };
        Object.keys(prevLoading).forEach((fileName) => {
          if (!currentlySelectedFiles.has(fileName)) {
            delete newLoadingState[fileName];
          }
        });
        return newLoadingState;
      });
    }, [selectedFile]);

    useEffect(() => {
      setFileLoadingState(fileLoading);
    }, [fileLoading]);

    return (
      <div className="input-wrapper">
        <div className="inputbox-wrapper">
          <div className={clsx("input-box", audioBoxPreview && "reduced")}>
            <Form className="input-form" form={form}>
              {!isProjectApp && (
                <label htmlFor="file-uploader" className="upload-button">
                  Upload File
                  <input
                    type="file"
                    multiple
                    ref={uploadFileInput}
                    // disabled={fileLoading}
                    id="file-uploader"
                    onChange={uploadFileHandler}
                    accept=".png, .jpg, .jpeg, .pdf, .docx, .txt, image/jpg, image/jpeg, image/png"
                  />
                  <Tooltip
                    id="my-tooltip-1"
                    overlayInnerStyle={{
                      width: 400,
                    }}
                    title={
                      <>
                        Upload a document or image to use in this chat session.
                        The file will only be available in this conversation and
                        will not carry over to new chats. All the uploaded documents will be given to the model at once.
                        So if your document is long, some models may not be able to process it. 
                        In that case you can switch models and retry or upload fewer documents for that session.
                        <br />
                        <br />
                        Allowed formats: PDF, DOCX, TXT, JPG, PNG
                      </>
                    }
                    placement="topLeft"
                  >
                    <FontAwesomeIcon
                      data-tooltip-id="my-tooltip-1"
                      className={clsx("upload-icon")}
                      icon={faPaperclip}
                    />
                  </Tooltip>
                </label>
              )}
              {/* PROMPT GENERATOR */}
              <textarea
                className="ant-input"
                ref={inputRef}
                placeholder={
                  isProjectApp
                    ? projectData?.inputBoxPlaceholder ?? inputPlaceHolder
                    : inputPlaceHolder
                }
                value={message}
                onChange={setMessageHandler}
                onKeyDown={handleKeyPress}
              />
              {speechPreview && (
                <Tooltip
                  title={
                    !browserSupportsSpeechRecognition
                      ? "Browser doesn't support speech recognition"
                      : listening
                      ? "Stop microphone"
                      : "Use microphone"
                  }
                >
                  <button
                    className="upload-button"
                    onClick={recordingHandler}
                    disabled={!browserSupportsSpeechRecognition}
                  >
                    <FontAwesomeIcon
                      className="upload-icon"
                      icon={listening ? faStop : faMicrophone}
                    />
                  </button>
                </Tooltip>
              )}
              {/* GENERATE BUTTON */}
              <Button
                type="submit"
                className="send-btn"
                invertedTheme
                boldText
                onClick={generateMessageHandler}
                disabled={
                  (message.trim() === "" || isFilesLoading) && !isLoading
                }
              >
                <FontAwesomeIcon icon={isLoading ? faSquare : faArrowUp} />
              </Button>
            </Form>
            <div className="images-box">
              <div className="selected-images">
                {selectedFile.map((item, index) => {
                  const isFileLoading = fileLoadingState[item.name];
                  const isImage = item.type === "image";
                  return (
                    <div
                      className={clsx("media-box", isImage ? "image" : "file")}
                    >
                      {isFileLoading && (
                        <div className="media-progress">
                          <BounceLoader color="#fff" size={15} />
                        </div>
                      )}
                      <span
                        role="button"
                        tabIndex={index}
                        className="delete-btn"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") removeSelectedFile(item.name);
                        }}
                        onClick={() => removeSelectedFile(item.name)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                      {isImage && <img src={item.data} alt={item.name} />}
                      {!isImage && (
                        <>
                          <div className="thumb">
                            <FontAwesomeIcon icon={faFile} />
                          </div>
                          <div className="detail">
                            <h4>{item.name}</h4>
                            <p>Document</p>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>

            {fileSupportWarning && (
              <p className="desclaimer-message">
                The current model chosen does not support images so this request
                will be processed using another model.
              </p>
            )}
          </div>
          {audioBoxPreview && (
            <button className="headset-button" onClick={audioBoxPreviewHandler}>
              <FontAwesomeIcon icon={faHeadphones} />
            </button>
          )}
        </div>
        {/* PROMPT TAGLINE */}
        <div className={clsx("tagline", fileSupportWarning && "warning")}>
          {isProjectApp
            ? projectData?.desclaimerMessage ?? discalimerMessage
            : discalimerMessage}
        </div>
      </div>
    );
  }
);

export default InputBox;
