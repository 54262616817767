import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "../components";
import { CHAT_ROUTE } from "../constants";
import { PublicSharedLayout } from "../shared-layout";
import { getUserAuthentication, navigateToLogin } from "../utils";

const PublicRoute = ({ children }) => {
  return <PublicSharedLayout>{children}</PublicSharedLayout>;
};

const PrivateRoute = ({ children }) => {
  // CONST VALS
  const navigate = useNavigate();
  const isAuthenticated = getUserAuthentication();

  // HOOKS
  useEffect(() => {
    if (!isAuthenticated) {
      navigateToLogin();
      return;
    }
    const redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      navigate(window.location.pathname + redirectUrl);
      localStorage.removeItem("redirectUrl");
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) return <Loader />;
  return children;
};

const AuthRoute = ({ children }) => {
  // CONST VALS
  const navigate = useNavigate();
  const isAuthenticated = getUserAuthentication();

  // HOOKS
  useEffect(() => {
    if (isAuthenticated) {
      navigate(CHAT_ROUTE);
    }
  }, [isAuthenticated, navigate]);

  return children;
};

export { PublicRoute, PrivateRoute, AuthRoute };
