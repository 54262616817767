import React, { useState, useEffect } from "react";
import { Switch, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faChevronDown,
  faPhotoFilm,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { MODEL_OPTIONS, PROMPT_MODE_OPTIONS } from "../../../../../constants";
import { faFile, faImage } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import clsx from "clsx";
import "./styles.scss";

const SettingSidebar = ({
  preview,
  previewToggle,
  selectedModel,
  setModel,
  libraryHander,
  selectedMedia = [],
  isProjectApp,
  appConfig,
  removeMedia,
  restrictAsuData,
  setRestrictAsuData,
  searchFromMyFiles,
  setSearchFromMyFiles,
  selectedPromptMode,
  setPromptMode,
}) => {
  // STATES
  const [modelListPreview, setModelListPreview] = useState(false);
  const [promptModePreview, setpromptModePreview] = useState(false);

  // REDUX DATA
  const media = useSelector(({ general }) => general.media);

  // CONST VALS
  const modelOptions = MODEL_OPTIONS.filter(
    (x) => x.value !== selectedModel.value
  );
  const myFileToggleEnabled = media.length >= 1;

  // HANDLERS
  const setModalListPreviewHandler = () => {
    setModelListPreview(!modelListPreview);
  };
  const setSelectedModalHandler = (item) => {
    setModel(item);
    setModelListPreview(false);
  };

  const selectPromptModeHandler = (item) => {
    setPromptMode(item);
    setpromptModePreview(false);
  };

  useEffect(() => {
    // function to close model list preview on outside click
    document.addEventListener("click", (e) => {
      if (
        !e.target.closest(".model-selector") &&
        !e.target.closest(".model-options")
      ) {
        setModelListPreview(false);
      }
    });
  }, []);

  useEffect(() => {
    if (media.length === 0 && searchFromMyFiles) setSearchFromMyFiles(false);
  }, [media]);

  return (
    <div
      className={clsx("setting-sidebar customize-form", preview && "active")}
      data-testid="chat-sidebar"
    >
      <div className="top-box">
        {!appConfig?.isDefault && (
          <div className="project-detail-box">
            <h4>{appConfig?.title}</h4>
            <p>{appConfig?.description}</p>
          </div>
        )}
        {/* INITIATE CHAT BOX */}
        <div className="initiate-box">
          {/* MODEL SELECTOR */}
          {appConfig.modelselector && (
            <div
              data-testid="model-selector"
              className={clsx("model-selector", modelListPreview && "active")}
            >
              <button
                className="selected-box"
                onClick={setModalListPreviewHandler}
              >
                <FontAwesomeIcon className="toggle" icon={faChevronDown} />
                <span className="title">Current Model</span>
                <span className="selected">
                  {selectedModel.label}
                  {selectedModel.leafs && (
                    <span className="eco">
                      {Array.from(Array(selectedModel.leafs)).map(
                        (_, index) => (
                          <FontAwesomeIcon icon={faLeaf} key={index} />
                        )
                      )}
                    </span>
                  )}
                </span>
              </button>
              <div className="model-options">
                {modelOptions.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      setSelectedModalHandler(item);
                    }}
                  >
                    {item.label}
                    {item.leafs && (
                      <span className="eco">
                        {Array.from(Array(item.leafs)).map((_, index) => (
                          <FontAwesomeIcon icon={faLeaf} key={index} />
                        ))}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          )}
          {/* {appConfig?.restrictDataOption && (
            <div
              data-testid="restrict-asudata-box"
              role="button"
              tabIndex={0}
              className="restrict-box customize-form"
              onClick={setRestrictAsuData}
              onKeyDown={setRestrictAsuData}
            >
              <span>Restrict to only ASU data</span>
              <Switch checked={restrictAsuData} />
            </div>
          )} */}
          {appConfig?.uploadfiles && (
            <div
              data-testid="restrict-myfiles-box"
              role="button"
              tabIndex={0}
              className={clsx(
                "restrict-box customize-form",
                !myFileToggleEnabled && "disabled"
              )}
              onClick={() => myFileToggleEnabled && setSearchFromMyFiles()}
              onKeyDown={() => myFileToggleEnabled && setSearchFromMyFiles()}
            >
              <span>Restrict to my files</span>
              <Switch checked={searchFromMyFiles} />
            </div>
          )}
          {searchFromMyFiles && (
            <>
              <p className="field-label">
                Please select one of the following options for how you want your
                uploaded data to be used:
              </p>
              <div
                className={clsx(
                  "prompt-mode-dropdown",
                  promptModePreview && "active"
                )}
              >
                <button
                  className="selected-option"
                  onClick={() => setpromptModePreview(!promptModePreview)}
                >
                  <h4>{selectedPromptMode.title}</h4>
                  <p>{selectedPromptMode.description}</p>
                  <span className="toggle-btn">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </span>
                </button>
                <div className="dropdown-options">
                  {PROMPT_MODE_OPTIONS.map((item) => (
                    <button
                      key={item.value}
                      onClick={() => selectPromptModeHandler(item)}
                      className={clsx(
                        selectedPromptMode.value === item.value && "selected"
                      )}
                    >
                      <span>{item.title}</span>
                      <span>{item.description}</span>
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
          {/* CHAT OPTIONS */}
          {/* <ul className="chat-options">
          {CHAT_OPTIONS.map((item, index) => (
            <li key={index} className="chat-option">
              New {item.label}
            </li>
          ))}
        </ul> */}
        </div>
      </div>
      {appConfig?.uploadfiles && (
        <>
          <button
            data-testid="media-library"
            className="media-library"
            onClick={libraryHander}
          >
            <FontAwesomeIcon icon={faPhotoFilm} />
            <span>Media Library</span>
          </button>
          {selectedMedia.length > 0 && (
            <div className="selected-media-box">
              <h3>Selected Media</h3>
              <ul>
                {selectedMedia.map((name, index) => {
                  const item = media.find((x) => x.name === name);
                  return (
                    <li key={index}>
                      <FontAwesomeIcon
                        className="thumb"
                        icon={item?.isImage ? faImage : faFile}
                      />
                      {item.name}{" "}
                      <Tooltip title="Deselect media">
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="delete-btn"
                          onClick={() => removeMedia(name)}
                        />
                      </Tooltip>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SettingSidebar;
