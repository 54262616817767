import { createSlice } from "@reduxjs/toolkit";

const ProjectReducer = createSlice({
  name: "projects",
  initialState: {
    projectData: {},
  },
  reducers: {
    // PROJECTS
    getProjectDetailRequest() {},
    getProjectDetailSuccess(state, { payload }) {
      state.projectData = payload;
    },
  },
});

export const { getProjectDetailRequest, getProjectDetailSuccess } =
  ProjectReducer.actions;

export default ProjectReducer.reducer;
