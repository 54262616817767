import React from "react";
import { Images } from "../../theme";
import "./styles.scss";

const AccessDenied = () => {
  return (
    <section className="access-denied-wrapper">
      <div className="content-box">
        <img src={Images.AccessThumbnail} className="thumb" alt="" />
        <div className="content">
          <h4>Oops!</h4>
          <h2>Access denied</h2>
          <p>
            Sorry, you don't currently have permission to access this page. If
            you are a student please contact your professor for access.
          </p>
          <p>Faculty or staff please request an access below:</p>
          <a className="request-btn" href="https://asu.edu">
            Request access
          </a>
        </div>
      </div>
    </section>
  );
};

export default AccessDenied;
