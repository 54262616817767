import React from "react";
import { Modal as AntdModal } from "antd";
import "./styles.scss";

const Modal = ({
  title = "",
  className = "",
  width = 500,
  open = false,
  openHandler = () => {},
  children,
}) => {
  return (
    <AntdModal
      centered
      width={width}
      onCancel={openHandler}
      footer={null}
      title={null}
      open={open}
      className={`modal-component ${className}`}>
      <h2 className="modal-title">{title}</h2>
      <div className="content-box">{children}</div>
    </AntdModal>
  );
};

export default Modal;
