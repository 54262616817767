import { combineReducers } from "@reduxjs/toolkit";

import general from "./general";
import user from "./user";
import chat from "./chat";
import project from "./project";

export default combineReducers({
  general,
  user,
  chat,
  project,
});
