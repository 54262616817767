import { useCallback } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { navigateToLogin, getAppEnv, getCurrentAccessToken } from "../utils";

export const WebSocketHandlers = () => {
  // HOOKS
  const getSocketUrl = useCallback(() => {
    return new Promise((resolve) => {
      let token = getCurrentAccessToken();
      resolve(`${getAppEnv().socketUrl}?access_token=${token}`);
    });
  }, []);

  // CONST VALS
  const { sendJsonMessage, lastMessage, readyState, getWebSocket } =
    useWebSocket(getSocketUrl, {
      onOpen: () => {},
      shouldReconnect: () => true,
      onError: () => {
        navigateToLogin();
      },
    });

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Connected",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return {
    sendJsonMessage,
    lastMessage,
    status: connectionStatus,
    getWebSocket,
  };
};
