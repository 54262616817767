import { call, fork, put, take } from "redux-saga/effects";
import { callRequest, PROJECT } from "../../config/web-service";
import { ALERT_TYPES } from "../../constants";
import { toastAlert } from "../../utils";
import {
  getProjectDetailRequest,
  getProjectDetailSuccess,
} from "../slicers/project";
import { manipulateProjectDetailData } from "../../data-manipulator/project";

// GET PROJECT DETAIL
function* getProjectDetail() {
  while (true) {
    const {
      payload: { payload, onlyResponse, responseCallback },
    } = yield take(getProjectDetailRequest.type);
    try {
      const response = yield call(callRequest, {
        url: PROJECT,
        payload,
      });
      if (response.status && !onlyResponse) {
        yield put(
          getProjectDetailSuccess(
            manipulateProjectDetailData(response?.data)
          )
        );
      }
      responseCallback?.(
        response?.status,
        manipulateProjectDetailData(response?.data)
      );

      !response.status && toastAlert(response.message, ALERT_TYPES.ERROR);
    } catch (err) {
      responseCallback?.(false, err);
      toastAlert(err.message, ALERT_TYPES.ERROR);
    }
  }
}

export default function* root() {
  yield fork(getProjectDetail);
}
