import React, { useState, useEffect } from "react";
import { ConfirmationModal, Loader } from "../../../components";
import { AccessDenied } from "../../";
import {
  ChatBox,
  MediaLibrary,
  Header,
  SettingSidebar,
  ChatSidebar,
  AudioBox,
} from "./partials";
import {
  MODEL_OPTIONS,
  PROMPT_MODE_OPTIONS,
  SIDEBAR_PLACEMENT_RIGHT,
} from "../../../constants";
import { CustomDispatch } from "../../../helpers";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrownOpen } from "@fortawesome/free-regular-svg-icons";
import { getAppConfig, getHostDetail, setAccessToken } from "../../../utils";
import { getProjectDetailRequest } from "../../../redux/slicers/project";
import { useParams, useSearchParams } from "react-router-dom";
import {
  deleteMediaRequest,
  getMediaListingRequest,
} from "../../../redux/slicers/general";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import "./styles.scss";

const Chat = () => {
  // STATES
  const [isLoading, setLoading] = useState(true);
  const [accessDenied, setAccessDenied] = useState(false);
  const [audioBoxPreview, setAudioBoxPreview] = useState(false);
  const [chatSidebarPreview, setChatSidebarPreview] = useState(true);
  const [settingSidebarPreview, setSettingSidebarPreview] = useState(true);
  const [libraryPreview, setLibraryPreview] = useState(false);
  const [deleteMediaPreview, setDeleteMediaPreview] = useState(false);
  const [isDeleteFromLibrary, setdeleteFromLibrary] = useState(false);
  const [deletedMedia, setdeletedMedia] = useState(null);
  const [selectedModel, setselectedModel] = useState(MODEL_OPTIONS[0]);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [restrictAsuData, setrestrictAsuData] = useState(false);
  const [searchFromMyFiles, setSearchFromMyFiles] = useState(false);
  const [selectedPromptMode, setselectedPromptMode] = useState(
    PROMPT_MODE_OPTIONS[0]
  );

  // CUSTOM DISPATCH
  const [getMedia, isMediaLoading] = CustomDispatch(getMediaListingRequest);
  const [getMediaInterval] = CustomDispatch(getMediaListingRequest);
  const [deleteMedia, deleteLoading] = CustomDispatch(deleteMediaRequest);
  const [getProjectDetail, detailLoader] = CustomDispatch(
    getProjectDetailRequest,
    true
  );

  // REDUX DATA
  const { projectData } = useSelector(({ project }) => project);

  // CONST VALS
  const [searchParams] = useSearchParams();
  const { projectslug } = useParams();
  const isProjectApp = getHostDetail();
  const appConfig = getAppConfig();
  const devToken = searchParams.get("token");

  // HELPERS
  const getMediaListing = (isInterval) => {
    if (isProjectApp || !appConfig?.uploadfiles) return;
    const request = isInterval ? getMediaInterval : getMedia;
    const payload = {
      client_method: "list",
    };
    request({
      payload,
      callback: () => setLoading(false),
      error: () => setAccessDenied(true),
    });
  };

  // HANDLERS
  const setChatSidebarPreviewHandler = () => {
    setChatSidebarPreview(!chatSidebarPreview);
  };
  const audioBoxPreviewHandler = () => {
    setAudioBoxPreview(!audioBoxPreview);
  };
  const setSettingSidebarPreviewHandler = () => {
    setSettingSidebarPreview(!settingSidebarPreview);
  };
  const setSelectedModalHandler = (item) => {
    setselectedModel(item);
  };
  const setSelectedPromptModeHandler = (item) => {
    setselectedPromptMode(item);
  };
  const setLibraryPreviewHandler = () => {
    setLibraryPreview(!libraryPreview);
  };
  const setrestrictAsuDataHandler = () => {
    if (searchFromMyFiles) setSearchFromMyFilesHandler();
    setrestrictAsuData(!restrictAsuData);
  };
  const setSearchFromMyFilesHandler = () => {
    // if (restrictAsuData) setrestrictAsuDataHandler();
    setSearchFromMyFiles(!searchFromMyFiles);
  };
  const openDeleteMediaHandler = (item, fromLibrary) => {
    setLibraryPreview(false);
    setDeleteMediaPreview(true);
    setdeletedMedia(item);
    if (fromLibrary) setdeleteFromLibrary(true);
  };
  const deleteMediaPreviewHandler = () => {
    if (isDeleteFromLibrary) setLibraryPreview(!libraryPreview);
    setdeleteFromLibrary(false);
    setDeleteMediaPreview(!deleteMediaPreview);
    setdeletedMedia(null);
  };
  const deleteMediaHandler = () => {
    const payload = {
      client_method: "delete",
      files: deletedMedia,
    };
    deleteMedia({
      payload,
      success: () => {
        deleteMediaPreviewHandler();
        getMediaListing();
      },
    });
  };
  const selectMediaHandler = (media) => {
    setSelectedMedia(media);
  };
  const removeMediaHandler = (media) => {
    const temp = [...selectedMedia];
    const index = temp.findIndex((item) => item === media);
    if (index > -1) {
      temp.splice(index, 1);
    }
    setSelectedMedia(temp);
  };

  // HOOKS
  useEffect(() => {
    async function setAccessTokenHandler() {
      if (!devToken) {
        getMediaListing();
        return;
      }
      await setAccessToken(devToken);
      getMediaListing();
    }
    setAccessTokenHandler();
  }, []);

  useEffect(() => {
    if (appConfig?.enableSearch) setrestrictAsuData(true);
    if (appConfig?.model) {
      if (appConfig?.model === "random") {
        const model =
          MODEL_OPTIONS[Math.floor(Math.random() * MODEL_OPTIONS.length) + 0];
        setSelectedModalHandler(model);
        return;
      }
      const model = MODEL_OPTIONS.find((x) => x.value === appConfig.model);
      setSelectedModalHandler(model);
    }
  }, [appConfig]);

  useEffect(() => {
    if (isProjectApp && projectslug) {
      const payload = {
        resource: "interface",
        method: "describe",
        details: {
          project_id: projectslug,
        },
      };
      getProjectDetail({
        payload,
        callback: () => setLoading(false),
        error: () => setAccessDenied(true),
      });
    }
  }, [isProjectApp]);

  // show loader while media is loading
  if (isLoading) return <Loader />;

  // show access denied if user is not allowed to access app
  if (accessDenied) return <AccessDenied />;

  return (
    <main
      className={clsx(
        "layout-wrapper",
        isProjectApp && "project-app",
        SIDEBAR_PLACEMENT_RIGHT && "rtl"
      )}
    >
      {isProjectApp && (
        <Helmet>
          <title>{projectData?.webTitle}</title>
        </Helmet>
      )}
      {isProjectApp && detailLoader ? (
        <Loader />
      ) : isProjectApp && projectData?.isLocked ? (
        <div className="lock-screen" data-testid="lock-screen">
          <FontAwesomeIcon icon={faFaceFrownOpen} className="thumb" />
          <h3>Sorry you don't have access!</h3>
        </div>
      ) : (
        <>
          <Header
            isProjectApp={isProjectApp}
            projectData={projectData}
            setChatSidebarPreview={setChatSidebarPreviewHandler}
            setSettingSidebarPreview={setSettingSidebarPreviewHandler}
          />
          <div
            className={clsx(
              "chat-layout",
              !chatSidebarPreview && "chat-sidebar-active",
              !settingSidebarPreview && "setting-sidebar-active"
            )}
          >
            {(!chatSidebarPreview || !settingSidebarPreview) && (
              <button
                data-testid="sidebar-backdrop"
                className="sidebar-backdrop"
                onClick={setChatSidebarPreviewHandler}
              />
            )}
            <ChatSidebar
              isProjectApp={isProjectApp}
              appConfig={appConfig}
              preview={chatSidebarPreview}
              projectId={projectslug}
            />
            {!isProjectApp && (
              <SettingSidebar
                isProjectApp={isProjectApp}
                appConfig={appConfig}
                selectedModel={selectedModel}
                setModel={setSelectedModalHandler}
                selectedPromptMode={selectedPromptMode}
                setPromptMode={setSelectedPromptModeHandler}
                restrictAsuData={restrictAsuData}
                setRestrictAsuData={setrestrictAsuDataHandler}
                searchFromMyFiles={searchFromMyFiles}
                setSearchFromMyFiles={setSearchFromMyFilesHandler}
                preview={settingSidebarPreview}
                previewToggle={setChatSidebarPreviewHandler}
                libraryHander={setLibraryPreviewHandler}
                selectedMedia={selectedMedia}
                removeMedia={removeMediaHandler}
              />
            )}
            {audioBoxPreview ? (
              <AudioBox
                previewHandler={audioBoxPreviewHandler}
                appConfig={appConfig}
                isProjectApp={isProjectApp}
                projectId={projectslug}
              />
            ) : (
              <ChatBox
                appConfig={appConfig}
                restrictAsuData={restrictAsuData}
                searchFromMyFiles={searchFromMyFiles}
                isProjectApp={isProjectApp}
                audioPreviewHandler={audioBoxPreviewHandler}
                selectedModel={selectedModel}
                selectedPromptMode={selectedPromptMode}
                selectedMedia={selectedMedia}
                projectId={projectslug}
              />
            )}
          </div>
          <MediaLibrary
            getMediaListing={getMediaListing}
            isMediaLoading={isMediaLoading}
            deleteMediaToggle={openDeleteMediaHandler}
            preview={libraryPreview}
            previewHandler={setLibraryPreviewHandler}
            selectedMedia={selectedMedia}
            selectMedia={selectMediaHandler}
          />
          {/* DELETE MEDIA MODAL */}
          <ConfirmationModal
            title="Delete Media"
            description="Are you sure you want to delete this media?"
            isLoading={deleteLoading}
            preview={deleteMediaPreview}
            previewHandler={deleteMediaPreviewHandler}
            confirmBtnHandler={deleteMediaHandler}
          />
        </>
      )}
    </main>
  );
};

export default Chat;
