import React from "react";
import { Images } from "../../../../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCirclePlus, faCog } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CHAT_ROUTE, PROJECT_CHAT_ROUTE } from "../../../../../constants";
import "./styles.scss";

const Header = ({
  isProjectApp,
  projectData,
  setChatSidebarPreview,
  setSettingSidebarPreview,
}) => {
  // CONST VALS
  const [searchParams] = useSearchParams();
  const chatScreen = searchParams.get("chat");
  const { projectslug } = useParams();
  const navigate = useNavigate();

  // HANDLERS
  const newChatHandler = () => {
    const projectRoute = PROJECT_CHAT_ROUTE.replace(
      ":projectslug",
      projectslug
    );
    const route = isProjectApp ? projectRoute : CHAT_ROUTE;
    navigate(route);
  };
  return (
    <header className="top-header">
      <div className="left-box">
        <button className="sidebar-toggle" onClick={setChatSidebarPreview}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className={clsx("logo-box", !isProjectApp && "text")}>
          <img src={isProjectApp ? Images.Logo : Images.LogoText} alt="" />
        </div>
      </div>
      {isProjectApp && (
        <h1 className="project-title">{projectData?.webTitle}</h1>
      )}
      {chatScreen && (
        <button className="new-chat" onClick={newChatHandler}>
          <span className="thumb">
            <FontAwesomeIcon icon={faCirclePlus} />
          </span>
          <span className="title">New Chat</span>
        </button>
      )}
      {!isProjectApp && (
        <button className="setting-toggle" onClick={setSettingSidebarPreview}>
          <FontAwesomeIcon icon={faCog} />
        </button>
      )}
    </header>
  );
};

export default Header;
